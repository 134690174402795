//each form is in a table_container which allows search to return the entire table when searched
//this object allows jquery events to still function when the new table is returned to the container
var table_container = $('.table_container');

$(function () {

    //setup the x-csrf-token to allow ajax requests to run correctly
    ajax_setup();

    //select dropdown default plugin
    $('.selectize').selectize();

    /**
     * global class used to edit data in a table
     *
     * if a td has class "exclude" its excluded from being clickable.
     */
    table_container.on('click', '.edit_data td:not(.exclude)', function () {

        //parent is where the path data is held
        window.location.href = $(this).parent('tr').data('path');
    });

    /**
     * Check before deleting an item on the standard index page for each table in admin
     */
    table_container.on('click', '.delete_form > .delete_button', function (e) {

        if (!confirm('Are you sure you want to delete this?'))
            e.preventDefault();
    });
});
