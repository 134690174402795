"use strict";
$(function () {

	set_cropping_tool({
		buttonDone: false
	});

	image_cropping_event();

	if ($('#image_cropping_file_manager_container')) {

		$('#image_cropping_file_manager_container').elfinder({
			height: 500,
			url: '/libs/file_manager/php/connector.minimal.php',
			getFileCallback: function (data) {

				$('#image_cropping_file_manager_modal').modal('hide');
				var element_id               = $('#image_cropping_file_manager_modal').data('element_id');
				var image_cropping_container = $('.image_cropping_container[data-element_id="' + element_id + '"]');
				var source                   = data.url;

				set_cropping_tool({
					image_cropping_container: image_cropping_container,
					source: source
				});
			}
		});
	}
});

function set_cropping_tool(data = {}) {

	var buttonDone               = data.buttonDone ? data.buttonDone : true;
	var buttonDel                = data.buttonDel ? data.buttonDone : true;
	var buttonCancel             = data.buttonCancel ? data.buttonDone : true;
	var image_cropping_container = data.image_cropping_container ? data.image_cropping_container : $('.image_cropping_container');

	image_cropping_container.each(function (key, value) {

		var element_id      = data.element_id ? data.element_id : $(this).data('element_id');
		var cropping_height = data.height ? data.height : $(this).data('cropping_height');
		var cropping_width  = data.width ? data.width : $(this).data('cropping_width');
		var save_path       = data.save_path ? data.save_path : $(this).data('save_path');
		var source          = data.source ? data.source : get_image_cropping_cropped_source({element_id: element_id});

		$(this).css('width', cropping_width);
		$(this).css('height', cropping_height);

/*
		if (source && source.match('/cropped/')) {
			buttonDone = data.buttonDone ? data.buttonDone : false;
			buttonDel  = false;

			set_image_cropping_cropped_source({
				source: source,
				element_id: element_id
			});
		}
*/

		$(this).html('<input data-toggle="modal" id="image_cropping_input" data-target="#image_cropping_file_manager_modal" type="file" name="thumb"/>').html5imageupload({
			image: source,
			buttonDone: buttonDone,
			buttonDel: buttonDel,
			buttonCancel: buttonCancel,
			height: cropping_height,
			width: cropping_width,
			resize: false,
			editstart: true,
			originalsize: false,
			url: '/libs/image_cropping/canvas.php',
			data: {
				save_path: save_path
			},
			onAfterProcessImage: function (data) {

				var source = $(this.element).data('name');

				set_image_cropping_cropped_source({
					source: source,
					element_id: element_id
				});
			},
			onAfterCancel: function () {

                set_image_cropping_cropped_source({
                                                      source: '',
                                                      element_id: element_id
                                                  });

				image_cropping_event();
			}
		});
	});
}

function image_cropping_event() {

	$('.image_cropping_container').on('click', '#image_cropping_input', function (e) {

		e.preventDefault();

		var image_cropping_container          = $(this).parent('.image_cropping_container');
		var element_id                        = image_cropping_container.data('element_id');
		var image_cropping_file_manager_modal = $('#image_cropping_file_manager_modal');

		image_cropping_file_manager_modal.data('element_id', element_id);
		image_cropping_file_manager_modal.modal('show');

		return false;
	});
}

function set_image_cropping_cropped_source(data) {

	return $('input[data-element_id="' + data.element_id + '"]').val(data.source);
}

function get_image_cropping_cropped_source(data) {

	return $('input[data-element_id="' + data.element_id + '"]').val();
}
